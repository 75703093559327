import React from 'react';
// import PropTypes from 'prop-types';
import { Router } from '@reach/router';
// import AWSAppSyncClient from 'aws-appsync';
import { ApolloProvider } from 'react-apollo';
// import { Rehydrated } from 'aws-appsync-react';
import ApolloClient from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import 'isomorphic-fetch'; // Comment out this line results in an error ...
import { InMemoryCache } from 'apollo-cache-inmemory';

import AppSyncConfig from '../aws-exports';
import Layout from '../components/layouts/Layout';
import Details from '../components/screens/Details';
import Home from '../components/screens/Home';
import Login from '../components/screens/Login';
import SignUp from '../components/screens/SignUp';
import PrivateRoute from '../components/utils/PrivateRoute';

const httpLink = createHttpLink({
  uri: AppSyncConfig.graphqlEndpoint,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-api-key': AppSyncConfig.apiKey,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const App = () => (
  <ApolloProvider client={client}>
    {/* <Rehydrated> */}
    <Layout>
      <Router>
        <PrivateRoute path="/app/home" component={Home} />
        <PrivateRoute path="/app/profile" component={Details} />
        <Login path="/app/login" />
        <SignUp path="/app/signup" />
      </Router>
    </Layout>
    {/* </Rehydrated> */}
  </ApolloProvider>
);

App.propTypes = {};

App.defaultProps = {};

export default App;
