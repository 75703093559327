import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
// imports from Amplify library
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { StaticQuery, graphql, Link } from 'gatsby';

import styled from 'styled-components';

import Layout from '../layouts/Layout';
import logo from '../../images/logo.svg';
import config from '../../aws-exports';
import { listEvents } from '../../graphql/queries';
import { client } from '../../pages/app';

// import variables from '../../styles/variables';
Amplify.configure(config);

const HomeScreen = () => {
  const [events, updateEvents] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const eventData = await await API.graphql(graphqlOperation(listEvents));
      console.log('data from API: ', eventData);
      updateEvents(eventData.data.listEvents.items);
    } catch (err) {
      console.log('error fetching data..', err);
    }
    // await client.hydrated();

    // const result = await client.watchQuery({
    //   query: gql(listEvents),
    //   fetchPolicy: 'cache-and-network',
    // });
    // // updateEvents(result.data.listEvents.items);
    // console.log('???', result);
  }

  return (
    <Layout>
      <Image src={logo} alt="logo" />
      <Title>The community network for motorcycle riders.</Title>
      <Form
        action="https://tinyletter.com/motobike"
        method="post"
        target="popupwindow"
        onSubmit="window.open('https://tinyletter.com/motobike', 'popupwindow', 'scrollbars=yes,width=800,height=600');return true"
      >
        <p>
          <label for="tlemail">
            Be <strong>the first in line</strong> when we launch!
          </label>
        </p>
        <p>
          <input type="text" name="email" id="tlemail" placeholder="hello@friend.com" />
        </p>
        <input type="hidden" value="1" name="embed" />
        <input type="submit" value="Subscribe" />
      </Form>
      {/*
      {events.map(event => (
        <div key={event.id}>
          <h1>AppSync - {event.name}</h1>
        </div>
      ))}
      <StaticQuery
        query={graphql`
          query listEvents {
            event {
              listEvents {
                items {
                  id
                  name
                  rideIns {
                    items {
                      id
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ event }) => {
          const { items } = event.listEvents;
          return (
            <div>
              {items.map(item => (
                <div key={item.id}>
                  <h2>{item.name}</h2>
                </div>
              ))}
            </div>
          );
        }}
      />
      */}
    </Layout>
  );
};

HomeScreen.propTypes = {};

HomeScreen.defaultProps = {};

const Image = styled.img`
  width: 200px;
  display: block;
  margin: 100px auto;
`;

const Title = styled.h1`
  font-size: 24px;
  text-align: center;
`;

const Form = styled.form`
  margin: 0 auto;
  text-align: center;
  font-family: Helvetica;
  strong {
    font-weight: normal;
    border-bottom: 2px solid white;
  }
  input[type='text'] {
    background-color: #202020;
    border: none;
    border-radius: 4px;
    padding: 8px 4px;
    color: white;
    text-align: center;
    border: 2px solid transparent;
    &:focus {
      border: 2px solid #e74c3c;
      outline: none;
    }
  }
  input[type='submit'] {
    border-radius: 4px;
    background-color: #e74c3c;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    font-family: Helvetica;
    border: none;
    padding: 4px 16px;
    letter-spacing: 1px;
    font-weight: 500;
  }
`;

export default HomeScreen;
