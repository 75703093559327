// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:22594e82-6558-4db3-9356-b02bf0607aff",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ZSsoGSrQ6",
    "aws_user_pools_web_client_id": "4u2114ncc8arurdilqt25359hl",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://wrjumsis6fd6nfauhgg7wlzxsq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-efntitsjnfbg7ax6n5m6zn2u5y"
};


export default awsmobile;
