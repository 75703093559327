// eslint-disable
// this is an auto generated file. This will be overwritten

export const getEvent = `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    name
    rideIns {
      items {
        id
        title
      }
      nextToken
    }
  }
}
`;
export const listEvents = `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      rideIns {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getRideIn = `query GetRideIn($id: ID!) {
  getRideIn(id: $id) {
    id
    title
    event {
      id
      name
      rideIns {
        nextToken
      }
    }
    comments {
      items {
        id
        content
      }
      nextToken
    }
  }
}
`;
export const listRideIns = `query ListRideIns(
  $filter: ModelRideInFilterInput
  $limit: Int
  $nextToken: String
) {
  listRideIns(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      event {
        id
        name
      }
      comments {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getComment = `query GetComment($id: ID!) {
  getComment(id: $id) {
    id
    content
    rideIn {
      id
      title
      event {
        id
        name
      }
      comments {
        nextToken
      }
    }
  }
}
`;
export const listComments = `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      content
      rideIn {
        id
        title
      }
    }
    nextToken
  }
}
`;
